import { AppSessionCellId, CellId } from "@hex/common";

import { useSelector } from "../../redux/hooks.js";
import { appSessionMPSelectors } from "../../redux/slices/appSessionMPSlice";
import { useSessionContext } from "../../util/sessionContext.js";

/**
 * @deprecated
 *
 * Prefer using `appSessionMPSelectors.getAppSessionCellSelectors(appSessionId).selectByCellId`
 * if possible. If you _must_ have the entire map, use `appSessionMPSelectors.getAppSessionCellSelectors(appSessionId).selectCellIdToAppSessionCellId`
 */
export function useAppSessionCellIdMap(): Record<CellId, AppSessionCellId> {
  const { appSessionId } = useSessionContext();

  return useSelector((state) =>
    appSessionMPSelectors
      .getAppSessionCellSelectors(appSessionId)
      .selectCellIdToAppSessionCellId(state),
  );
}
